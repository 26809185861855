export let poseLandmarkToInt: { [id: string] : number } = {
    'NOSE': 0,
    'LEFT_EYE_INNER': 1,
    'LEFT_EYE': 2,
    'LEFT_EYE_OUTER': 3,
    'RIGHT_EYE_INNER': 4,
    'RIGHT_EYE': 5,
    'RIGHT_EYE_OUTER': 6,
    'LEFT_EAR': 7,
    'RIGHT_EAR': 8,
    'MOUTH_LEFT': 9,
    'MOUTH_RIGHT': 10,
    'LEFT_SHOULDER': 11,
    'RIGHT_SHOULDER': 12,
    'LEFT_ELBOW': 13,
    'RIGHT_ELBOW': 14,
    'LEFT_WRIST': 15,
    'RIGHT_WRIST': 16,
    'LEFT_PINKY': 17,
    'RIGHT_PINKY': 18,
    'LEFT_INDEX': 19,
    'RIGHT_INDEX': 20,
    'LEFT_THUMB': 21,
    'RIGHT_THUMB': 22,
    'LEFT_HIP': 23,
    'RIGHT_HIP': 24,
    'LEFT_KNEE': 25,
    'RIGHT_KNEE': 26,
    'LEFT_ANKLE': 27,
    'RIGHT_ANKLE': 28,
    'LEFT_HEEL': 29,
    'RIGHT_HEEL': 30,
    'LEFT_FOOT_INDEX': 31,
    'RIGHT_FOOT_INDEX': 32,
    'FAKE_HIP': 33,
}

export enum intToPoseLandmark {
    'NOSE',
    'LEFT_EYE_INNER',
    'LEFT_EYE',
    'LEFT_EYE_OUTER',
    'RIGHT_EYE_INNER',
    'RIGHT_EYE',
    'RIGHT_EYE_OUTER',
    'LEFT_EAR',
    'RIGHT_EAR',
    'MOUTH_LEFT',
    'MOUTH_RIGHT',
    'LEFT_SHOULDER',
    'RIGHT_SHOULDER',
    'LEFT_ELBOW',
    'RIGHT_ELBOW',
    'LEFT_WRIST',
    'RIGHT_WRIST',
    'LEFT_PINKY',
    'RIGHT_PINKY',
    'LEFT_INDEX',
    'RIGHT_INDEX',
    'LEFT_THUMB',
    'RIGHT_THUMB',
    'LEFT_HIP',
    'RIGHT_HIP',
    'LEFT_KNEE',
    'RIGHT_KNEE',
    'LEFT_ANKLE',
    'RIGHT_ANKLE',
    'LEFT_HEEL',
    'RIGHT_HEEL',
    'LEFT_FOOT_INDEX',
    'RIGHT_FOOT_INDEX',
    'FAKE_HIP',
}

export let childToParentLookup: { [id: number] : number } = {
    24: 33,
    23: 33,
    26: 24,
    12: 24,
    11: 23,
    25: 23,
    13: 11,
    9: 11,
    7: 11,
    3: 7,
    2: 3,
    1: 2,
    14: 12,
    8: 12,
    10: 12,
    6: 8,
    5: 6,
    4: 5,
    0: 4,
    15: 13,
    21: 15,
    19: 15,
    17: 15,
    16: 14,
    22: 16,
    20: 16,
    18: 16,
    27: 25,
    29: 27,
    31: 27,
    28: 26,
    32: 28,
    30: 28
}

export let parentToChildConstant: { [id: number] : number[] } = {
    33: [23, 24],
    24: [26, 12],
    23: [11, 25],
    11: [13, 9, 7],
    7: [3],
    3: [2],
    2: [1],
    12: [14, 8, 10],
    8: [6],
    6: [5],
    5: [4],
    4: [0],
    13: [15],
    15: [21, 19, 17],
    14: [16],
    16: [22, 20, 18],
    25: [27],
    27: [29, 31],
    26: [28],
    28: [32, 30],
}

export let distanceToRoot: { [id: number] : number } = {
    33: 0,
    24: 1,
    23: 1,
    26: 2,
    12: 2,
    11: 2,
    25: 2,
    28: 3,
    14: 3,
    8: 3,
    10: 3,
    13: 3,
    9: 3,
    7: 3,
    27: 3,
    32: 4,
    30: 4,
    16: 4,
    6: 4,
    15: 4,
    3: 4,
    29: 4,
    31: 4,
    22: 5,
    20: 5,
    18: 5,
    5: 5,
    21: 5,
    19: 5,
    17: 5,
    2: 5,
    4: 6,
    1: 6,
    0: 7
}

export let maxDistanceToRoot = 7;

export let boneLength: { [id: number]: number } = {
    24: 0.16020259186504046,
    23: 0.16020259186504046,
    26: 0.3317254048386577,
    12: 0.36651331401869197,
    11: 0.36651331401869197,
    25: 0.3317254048386577,
    28: 0.41141588262334894,
    14: 0.21700748895629493,
    8: 0.19682880251005383,
    10: 0.12971622879233174,
    13: 0.21700748895629493,
    9: 0.32115118465145187,
    7: 0.19682880251005383,
    27: 0.41141588262334894,
    32: 0.12968241500518402,
    30: 0.05265918371482957,
    16: 0.179358747710201,
    6: 0.13223577727790475,
    15: 0.179358747710201,
    3: 0.13223577727790475,
    29: 0.05265918371482957,
    31: 0.12968241500518402,
    22: 0.020374355911130036,
    20: 0.08097729916174443,
    18: 0.06566696469762162,
    5: 0.0006601418978802128,
    21: 0.020374355911130036,
    19: 0.08097729916174443,
    17: 0.06566696469762162,
    2: 0.0006601418978802128,
    4: 0.0014909423018950258,
    1: 0.0014909423018950258,
    0: 0.04152648859782476
}

export enum GameState {
    loading,
    waitingForCharacterInCamera,
    countingDownToStart,
    playing,
    reviewingScore,
}
