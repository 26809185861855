import React, { useEffect } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { GameState } from "./Utils/Constants";
import GameComponent from "./Components/GameComponent";
import StateManager from "./Utils/StateManager";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PoseScoreController from "./Controller/ScoreController";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

function App() {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [_gameState, _setGameState] = React.useState(GameState.loading);
    const isPlayingRef = React.useRef<boolean>(false);
    const startingCountDownDuration = 5;
    const endingCountDownDuration = 5;
    const [timer, setTimer] = React.useState(startingCountDownDuration);
    const [endingTimer, setEndingTimer] = React.useState(endingCountDownDuration);
    let timerRef = React.useRef<NodeJS.Timer | null>(null);
    const gameState = React.useRef<GameState>(_gameState);
    const scoreController = React.useRef<PoseScoreController>();
    const stateManager = React.useRef<StateManager>(new StateManager(_gameState));
    const setGameState = (modifiedGameState: GameState): void => {
        let changedGameState = stateManager.current.changeState(modifiedGameState);
        gameState.current = changedGameState;
        _setGameState(changedGameState);
    }

    const getSecondsRemaining = (e: Date) => {
        const seconds = e.getSeconds() - new Date().getSeconds();
        return seconds;
    }

    const getDeadTime = (countdownDuration: number) => {
        let deadline = new Date();

        // This is where you need to adjust if
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + countdownDuration);
        return deadline;
    }

    function resetCountDown() {
        if (timerRef.current) clearInterval(timerRef.current);
        setTimer(startingCountDownDuration);
        setEndingTimer(endingCountDownDuration);
    }

    useEffect(() => {
        const updateTimer = (e: Date) => {
            let seconds = getSecondsRemaining(e);
            if (seconds >= 1) {
                // update the timer
                // check if less than 10 then we need to
                // add '0' at the beginning of the variable
                if (gameState.current === GameState.countingDownToStart) {
                    setTimer(seconds);
                } else if (gameState.current === GameState.reviewingScore) {
                    setEndingTimer(seconds);
                }
                return;
            }

            if (gameState.current === GameState.countingDownToStart) {
                // After the countdown finishes, we want the game to start
                setGameState(GameState.playing);
            } else if (gameState.current === GameState.reviewingScore) {
                setGameState(GameState.countingDownToStart);
                scoreController.current?.resetSessionStats();
            }
            resetCountDown();
        }

        isPlayingRef.current = _gameState === GameState.playing;
        if (timerRef.current && _gameState !== GameState.countingDownToStart) {
            resetCountDown();
        }

        if (_gameState === GameState.loading) {
            return;
        }

        if (_gameState === GameState.reviewingScore) {
            let timerExpiration = getDeadTime(endingCountDownDuration);
            timerRef.current = setInterval(() => {
                updateTimer(timerExpiration);
            }, 1000);
            return;
        }

        if (_gameState === GameState.waitingForCharacterInCamera) {
            isPlayingRef.current = false;
            return;
        }

        if (_gameState === GameState.countingDownToStart) {
            let timerExpiration = getDeadTime(startingCountDownDuration);
            timerRef.current = setInterval(() => {
                updateTimer(timerExpiration);
            }, 1000);
            return;
        }
    }, [_gameState]);

    const showBackdrop = () => {
        return _gameState === GameState.loading ||
        _gameState === GameState.waitingForCharacterInCamera ||
        _gameState === GameState.countingDownToStart;
    }

    const showEndOfGameDialog = () => {
        return _gameState === GameState.reviewingScore;
    }

    const getDialogScoreTable = () => {
        if (!scoreController.current) {
            return (
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <CircularProgress
                        size={"2vw"}
                    />
                </div>
            );
        }
        let totalValues = scoreController.current.totalSessionEvents;
        return (
            <div>
                {Object.keys(scoreController.current.sessionStats).map((key) => {
                    return (
                        <div
                            key={key}
                            style={{
                                display: "flex",
                                margin: "20px 0px",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                            <img src={key + "_Cropped.png"} alt={""} style={{
                                height: "10vh",
                            }}/>
                            <h2 style={{
                                margin: 0,
                            }}>
                                {"- " + (totalValues !== 0 ? (100 * scoreController.current!.sessionStats[key] / totalValues).toFixed(0) : 0) + "%"}
                            </h2>
                        </div>
                    )
                })}
                <Box sx={{ width: '100%' }}>
                    <h3>
                        Restarting in {endingTimer}
                    </h3>
                    <LinearProgress variant="determinate" value={Math.round((endingTimer/endingCountDownDuration) * 100)} />
                </Box>
            </div>
        )
    }

    const getBackdropChildren = () => {
        if (_gameState === GameState.loading) {
            return (
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                    <CircularProgress
                        size={"5vw"}
                    />
                    <h1 style={{
                        color: "white",
                        textAlign: "center",
                    }}>
                        Getting things ready
                    </h1>
                </div>
            )
        } else if (_gameState === GameState.waitingForCharacterInCamera) {
            return (
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                    <h1 style={{
                        color: "white",
                        textAlign: "center",
                    }}>
                        Step in front of the camera
                    </h1>
                </div>
            )
        } else if (_gameState === GameState.countingDownToStart) {
            return (
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                    <h1 style={{
                        color: "white",
                        textAlign: "center",
                    }}>
                        Get Ready to Dance
                    </h1>
                    <h1 style={{
                        color: "white"
                    }}>
                        {timer}
                    </h1>
                </div>
            )
        }
    }

    return (
        <div className="App">
            <Backdrop open={showBackdrop()} onClick={()=>{}}
                style={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 10000000,
                }}>
                {getBackdropChildren()}
            </Backdrop>
            <GameComponent
                gameState={gameState}
                setGameState={setGameState}
                poseScoreController={scoreController}
                isPlayingRef={isPlayingRef}
            />
            <Dialog
                fullScreen={fullScreen}
                maxWidth={"md"}
                open={showEndOfGameDialog()}>
                <DialogTitle>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                        }}>
                            <h2 style={{
                                margin: 0,
                                textAlign: "center",
                            }}>
                                Way to Get Your Groove On
                            </h2>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                            }}>
                                {getDialogScoreTable()}
                            </div>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>

                </DialogContent>
            </Dialog>
        </div>
    );
}

export default App;
