import React from "react";
import { GameState } from "../Utils/Constants";

class PoseScoreController {
    N: number;
    lastNScores: number[];
    cooldownCount: number;
    scoreImageRef: React.RefObject<HTMLImageElement>;
    videoRef: React.RefObject<HTMLVideoElement>;
    gameState: React.RefObject<GameState>;
    totalSessionEvents: number;
    sessionStats: { [key: string]: number };
    extremeDifficulty: boolean;
    setScore: React.Dispatch<React.SetStateAction<number>>;

    // Calculation based on the video that is passed in
    maxScorePerFrame: number | null;

    constructor(scoreImageRef: React.RefObject<HTMLImageElement>,
                videoRef: React.RefObject<HTMLVideoElement>,
                gameState: React.RefObject<GameState>,
                setScore: React.Dispatch<React.SetStateAction<number>>) {
        this.lastNScores = [];
        this.N = 1;
        this.cooldownCount = 10;
        this.scoreImageRef = scoreImageRef;
        this.videoRef = videoRef;
        this.maxScorePerFrame = null;
        this.setScore = setScore;
        this.gameState = gameState;
        this.sessionStats = {};
        this.resetSessionStats();
        this.totalSessionEvents = 0;
        this.extremeDifficulty = false;
    }

    public resetSessionStats() {
        this.sessionStats = {
            "Perfect": 0,
            "Good": 0,
            "Ok": 0,
            "Bad": 0,
        }
        this.totalSessionEvents = 0;
    }

    public calculateScore(distanceScore: number): void {
        if (this.gameState.current !== GameState.playing) {
            return;
        }

        if (!distanceScore) {
            return;
        }

        if (!this.videoRef.current) {
            return;
        }

        if (distanceScore !== Number.MAX_SAFE_INTEGER ||
            distanceScore !== Number.MIN_SAFE_INTEGER) {
            this.lastNScores = this.lastNScores.concat(distanceScore);
        }

        if (this.lastNScores.length >= this.N) {
            let sumDistance = this.lastNScores.reduce((a, b) => a + b, 0);
            let avgDistance = sumDistance / this.N;
            if (this.cooldownCount <= 0) {
                this.totalSessionEvents += 1;
                if (this.extremeDifficulty) {
                    if (avgDistance < 13) {
                        this.scoreImageRef.current!.src = "Perfect.png";
                        this.sessionStats["Perfect"] += 1;
                    } else if (avgDistance >= 13 && avgDistance < 16) {
                        this.scoreImageRef.current!.src = "Good.png";
                        this.sessionStats["Good"] += 1;
                    } else if (avgDistance >= 16 && avgDistance < 19) {
                        this.scoreImageRef.current!.src = "Ok.png";
                        this.sessionStats["Ok"] += 1;
                    } else {
                        this.scoreImageRef.current!.src = "Bad.png";
                        this.sessionStats["Bad"] += 1;
                    }
                } else {
                    if (avgDistance < 13) {
                        this.scoreImageRef.current!.src = "Perfect.png";
                        this.sessionStats["Perfect"] += 1;
                    } else if (avgDistance >= 13 && avgDistance < 18) {
                        this.scoreImageRef.current!.src = "Good.png";
                        this.sessionStats["Good"] += 1;
                    } else if (avgDistance >= 18 && avgDistance < 22) {
                        this.scoreImageRef.current!.src = "Ok.png";
                        this.sessionStats["Ok"] += 1;
                    } else {
                        this.scoreImageRef.current!.src = "Bad.png";
                        this.sessionStats["Bad"] += 1;
                    }
                }
                this.cooldownCount = this.N;
            } else if (this.cooldownCount <= this.N/3) {
                this.scoreImageRef.current!.src = "";
            }
            this.cooldownCount = this.cooldownCount - 1;

            this.lastNScores = this.lastNScores.slice(1);
        }

    }
}

export default PoseScoreController
