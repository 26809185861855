import { GameState } from "./Constants";

class StateManager {
    previousState: GameState;

    constructor(currentState: GameState) {
        this.previousState = currentState;
    }

    private setPreviousState(previousState: GameState) {
        this.previousState = previousState;
    }

    public changeState(modifiedState: GameState): GameState {
        console.log("Asked to set" + modifiedState);
        if (this.previousState === GameState.loading) {
            if (modifiedState === GameState.waitingForCharacterInCamera) {
                this.setPreviousState(modifiedState);
            } else if (modifiedState === GameState.countingDownToStart) {
                this.setPreviousState(modifiedState);
            }
        } else if (this.previousState === GameState.waitingForCharacterInCamera) {
            if (modifiedState === GameState.countingDownToStart) {
                this.setPreviousState(modifiedState);
            }
        } else if (this.previousState === GameState.countingDownToStart) {
            if (modifiedState === GameState.playing) {
                this.setPreviousState(modifiedState);
            } else if (modifiedState === GameState.waitingForCharacterInCamera) {
                this.setPreviousState(modifiedState);
            }
        } else if (this.previousState === GameState.playing) {
            if (modifiedState === GameState.waitingForCharacterInCamera) {
                this.setPreviousState(modifiedState);
            } else if (modifiedState === GameState.reviewingScore) {
                this.setPreviousState(modifiedState);
            }
        } else if (this.previousState === GameState.reviewingScore) {
            if (modifiedState === GameState.countingDownToStart) {
                this.setPreviousState(modifiedState);
            }
        }

        return this.previousState;
    }
}

export default StateManager
