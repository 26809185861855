import * as Pose from "@mediapipe/pose";
import React, { useEffect } from "react";
import * as cam from "@mediapipe/camera_utils";
import Webcam from "react-webcam";
import PoseResultController from "../Controller/PoseResultController";
import PoseScoreController from "../Controller/ScoreController";
import { GameState } from "../Utils/Constants";
import useWindowDimensions from "./UseWindowDimensions";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";

interface GameComponentProps {
    gameState: React.MutableRefObject<GameState>,
    poseScoreController: React.MutableRefObject<PoseScoreController | undefined>,
    setGameState: (gameState: GameState) => void,
    isPlayingRef: React.MutableRefObject<boolean>,
}

function GameComponent(props: GameComponentProps) {
    const { width } = useWindowDimensions();
    const webcamRef = React.useRef<Webcam>(null);
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const targetCanvasRef = React.useRef<HTMLCanvasElement>(null);
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const imageRef = React.useRef<HTMLImageElement>(null);
    const [difficulty, setDifficulty] = React.useState("Hard");
    const handleChange = (event: SelectChangeEvent) => {
        if (videoRef.current) {
            let difficultyLevel = event.target.value as string;
            setDifficulty(difficultyLevel);
            if (difficultyLevel === "Easy") {
                videoRef.current.playbackRate = 0.25;
            } else if (difficultyLevel === "Medium") {
                videoRef.current.playbackRate = 0.5;
            } else {
                videoRef.current.playbackRate = 1;
            }

            if (scoreController && scoreController.current) {
                if (difficultyLevel === "Extreme") {
                    scoreController.current.extremeDifficulty = true;
                } else {
                    scoreController.current.extremeDifficulty = false;
                }
            }
        }
    };

    const camera = React.useRef<cam.Camera>();
    const clientPose = React.useRef<Pose.Pose>(new Pose.Pose({
		locateFile: (file) => {
			return `./pose/${file}`;
		},
	}));
    const targetPose = React.useRef<Pose.Pose>(new Pose.Pose({
		locateFile: (file) => {
			return `./pose/${file}`;
		},
	}));
    const [score, setScore] = React.useState(0);
	clientPose.current.setOptions({
		modelComplexity: 1,
		smoothLandmarks: true,
		enableSegmentation: true,
		smoothSegmentation: true,
		minDetectionConfidence: 0.9,
		minTrackingConfidence: 0.9,
	});
	targetPose.current.setOptions({
		modelComplexity: 1,
		smoothLandmarks: true,
		enableSegmentation: true,
		smoothSegmentation: true,
		minDetectionConfidence: 0.9,
		minTrackingConfidence: 0.9,
	});

    const poseResultController = React.useRef<PoseResultController>(new PoseResultController(
        webcamRef,
        canvasRef,
        targetCanvasRef,
    ));

    const scoreController = React.useRef<PoseScoreController>(new PoseScoreController(
        imageRef,
        videoRef,
        props.gameState,
        setScore,
    ));

    function onEnded() {
        console.log("Video ended");
        props.setGameState(GameState.reviewingScore);
        if (imageRef.current) {
            imageRef.current.src = "";
        }
    }

    async function onFrame() {
        console.log("onFrame");
        let clientImageToProcess: Pose.InputImage = webcamRef.current?.video!;
        let clientPoseResult = clientPose.current.send({
            image: clientImageToProcess,
        });
        await clientPoseResult;

        let targetImageToProcess: Pose.InputImage = videoRef.current!;
        let targetPoseResult = targetPose.current.send({
            image: targetImageToProcess,
        });
        await targetPoseResult;

        if (!props.poseScoreController.current) {
            props.poseScoreController.current = scoreController.current;
        }

        if (props.setGameState != null && props.gameState.current !== GameState.reviewingScore) {
            if (poseResultController.current.clientInFrame === false) {
                props.setGameState(GameState.waitingForCharacterInCamera);
            } else if (props.gameState.current === GameState.loading ||
                       props.gameState.current === GameState.waitingForCharacterInCamera) {
                props.setGameState(GameState.countingDownToStart);
            }
        }

        if (props.gameState.current !== GameState.playing) {
            if (videoRef.current!) {
                videoRef.current!.pause();
            }
        }

        if (props.gameState.current === GameState.playing) {
            if (videoRef.current!.paused) {
                videoRef.current!.play();
            }

            let distanceScore = poseResultController.current.calculateTimestepEuclideanScore();
            scoreController.current.calculateScore(distanceScore);
            setScore(distanceScore);
        }
    }

    useEffect(() => {
        console.log("UseEffect game");
        console.log(camera);
        if (
            typeof webcamRef.current !== "undefined" &&
            webcamRef.current !== null &&
            videoRef.current !== null &&
            camera.current == null
        ) {
            camera.current = new cam.Camera(webcamRef.current?.video!, {
                onFrame: async () => onFrame(),
            });
            camera.current.start();
			clientPose.current.onResults(
				poseResultController.current.onPoseResults.bind(poseResultController.current)
			);
			targetPose.current.onResults(
				poseResultController.current.onTargetPoseResults.bind(poseResultController.current)
			);
        }
    });
    return (
        <div className="VideoAndCamera">
            <h1 style={{
                position: "absolute",
                left: "0px",
                marginLeft: "5px",
                textAlign: "center",
            }}>
                {score.toFixed(2)}
            </h1>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                style={{
                    position: "absolute",
                    right: "0px",
                    marginLeft: "5px",
                    textAlign: "center",
                    zIndex: 10000000,
                }}
                value={difficulty}
                label="Difficulty"
                onChange={handleChange}
            >
                <MenuItem value={"Easy"}>Easy</MenuItem>
                <MenuItem value={"Medium"}>Medium</MenuItem>
                <MenuItem value={"Hard"}>Hard</MenuItem>
                <MenuItem value={"Extreme"}>Extreme</MenuItem>
            </Select>
            <div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <img
                        ref={imageRef}
                        alt={""}
                        style={{
                            position: "absolute",
                            marginLeft: "auto",
                            marginRight: "auto",
                            left: "0",
                            right: "0",
                            textAlign: "center",
                            zIndex: "10000",
                        }}
                        src=""
                    ></img>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}>
                    <video
                        onEnded={onEnded}
                        ref={videoRef}
                        style={{
                            height: "100%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: props.gameState.current === GameState.loading ? "none" : "block",
                            position: "absolute",
                        }}
                        playsInline
                        controls>
                        <source
                            src="noah_beck.mp4"
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
            {/* <canvas
                ref={targetCanvasRef}
                className="output_canvas"
                style={{
                    position: "absolute",
                    left: 0,
                    bottom: 0,
                }}
            /> */}
            <div
                className="webcam-and-canvas"
                style={{
                    display: "grid",
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                }}>
                <Webcam
                    ref={webcamRef}
                    mirrored={true}
                    width={Math.max(Math.min(Math.round(width * 0.25), 640), 20)}
                    style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        left: 0,
                        textAlign: "center",
                        gridRow: 1,
                        gridColumn: 1,
                    }}
                />
                <canvas
                    ref={canvasRef}
                    className="output_canvas"
                    style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        left: 0,
                        textAlign: "center",
                        gridRow: 1,
                        gridColumn: 1,
                        transform: "scale(-1, 1)",
                    }}
                />
            </div>
        </div>
    );
}

export default GameComponent;
